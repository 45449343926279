@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

// PrimeNg
@import 'primeng';

// Tailwind CSS
@import 'tailwind';

// Librairies externes
@import 'notifier';

@import 'pages';

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.row-container {
  @apply tw-flex tw-flex-row;
}

.col-container {
  @apply tw-flex tw-flex-col;
}

.centering {
  @apply tw-items-center tw-justify-center;
}

.bg-container {
  @apply tw-bg-contact tw-bg-cover tw-flex tw-items-center tw-overflow-hidden tw-gap-y-10;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button-primary {
  @apply tw-bg-primary tw-font-semibold tw-border-0 tw-px-5 tw-py-2.5;
}

.button-primary:hover {
  @apply hover:tw-bg-blue-75;
}

.button-outlined-primary {
  @apply tw-bg-transparent tw-text-primary tw-font-semibold tw-px-5 tw-py-2.5;
  color: #122633 !important;
  border: 2px solid !important;
}

.button-outlined-primary:hover {
  background-color: #cad7df !important;
}

.button-brown {
  @apply tw-bg-brown tw-font-semibold tw-border-0 tw-px-5 tw-py-2.5;
}

.button-brown:hover {
  @apply hover:tw-bg-brown-20 hover:tw-text-brown;
}

.button-outlined-brown {
  @apply tw-bg-transparent tw-text-brown tw-font-semibold tw-px-5 tw-py-2.5;
  color: #a46421 !important;
  border: 2px solid !important;
}

.button-outlined-brown:hover {
  @apply hover:tw-bg-brown-20;
}

.button-white {
  @apply tw-bg-white tw-text-primary tw-font-semibold tw-border-0 tw-px-5 tw-py-2.5;
}

.button-white:hover {
  @apply hover:tw-bg-blue-25;
  color: #122633 !important;
}

.long-button {
  @apply tw-px-8 tw-py-2.5;
}
